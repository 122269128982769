import { Client } from '@googlemaps/google-maps-services-js';
import { openSnackbarMessage } from '@redux/actions/appsActions';
import {
  Box,
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  StyleRules,
  Theme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ECategoryType } from '@models/category-type';
import { IGeocode } from '@models/geocode';
import { ILocation } from '@models/location';
import { IQuery } from '@models/query';
import { IRegistrationState } from '@models/registration-state';
import { ISerial } from '@models/serial';
import { ESerialActionType } from '@models/serial-action-type';
import { ISerials } from '@models/serials';
import { IStateApps } from '@models/state-app';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import { clearScannedSerialCode, setBreweryRegState, setTab } from '@redux/actions/appsActions';
import appLanguages from '@utils/app-languages';
import { getBaiduScannedLocationInfo } from '@utils/baidu-utils/get-baidu-scanned-location-info';
import { checkSerialValidity } from '@utils/check-serial-validity';
import {
  BAIDU_ONLY_COUNTRIES,
  getAutocomplete,
  getScannedLocationDistanceView,
  getScannedSerialsView,
  getTextField,
  getTextFieldForReadOnly,
  setDistanceFromScanLocToUserLocByGoogleMapAPI,
  setScanLocationInfoByGoogleMapAPI,
} from '@utils/common';
import { DefaultMasterQuery } from '@utils/default-query';
import { getQueryForScannedSerials } from '@utils/get-scanned-serials-for-registration';
import { isOk } from '@utils/is-ok';
import React from 'react';
import { connect } from 'react-redux';
import userEnv from 'userEnv';

import { compose } from 'redux';

import {
  getLocations,
  getScannedSerials,
  registerSerial,
  registerSerials,
  setLocationsQuery,
} from '../../../redux/actions/serversActions';
import { SerialQRScan } from './SerialQRScan';

class SakeRegForBreweryClass extends React.PureComponent<Props, State> {
  private initState: State;

  constructor(props) {
    super(props);
    this.initState = {
      scanLat: 0,
      scanLng: 0,
      scanCountry: '',
      scanArea: '',
      scanCity: '',
      scanSubCity: '',
      scanPostalCode: '',
      distanceText: '',
      distance: 0,
      warning: false,
      openScannedSerials: true,
      openSerialBrandMap: {},
      openLocations: true,
    };
    this.state = {
      ...this.initState,
    };
  }
  restValue = { id: 0, name: '', mapAddress: '', mainAddress: '', customerCode: '' };
  step3DestinationDescription = '';

  async componentDidMount() {
    // 拠点（レストラン or ディストリビュータ）データ取得
    const {
      setLocationsQuery,
      getLocations,
      servers: { user },
    } = this.props;
    // category => { 0: 'None', 1: 'Brewery', 2: 'Distributor', 3: 'Restaurant' },
    const query = {
      ...DefaultMasterQuery,
      where: {
        hidden: false,
        $or: [{ category: ECategoryType.DISTRIBUTOR }, { category: ECategoryType.RESTAURANT }],
      },
    };
    setLocationsQuery(query);
    await getLocations(query);
    console.log('sakeregforbrew');
    // function sleep(waitMsec) {
    //   var startMsec = new Date();
    //   while (new Date().getTime() - startMsec.getTime() < waitMsec);
    // }
    // for (let i = 0; i < 5; i++) {
    //   window.navigator.geolocation.getCurrentPosition((p) => {
    //     const { latitude, longitude } = p.coords;
    //     console.log(latitude, longitude);
    //     sleep(1000);
    //   });
    // }

    openSnackbarMessage('success', 'user');
    if (BAIDU_ONLY_COUNTRIES.includes(user?.location?.country)) {
      await this.initScanLocationByBaiduMapAPI();
    } else {
      this.initScanLocationByGoogleMapAPI();
    }
  }

  getStepContent(step) {
    const {
      classes,
      apps,
      servers,
      apps: {
        breweryRegState: {
          locCategory,
          restLocId,
          subCategory,
          slipNum,
          customerNumber,
          eventName,
          purpose,
        },
        serialRegistrationDate,
      },
    } = this.props;
    //let { restValue, step3DestinationDescription } = this.state;
    const { user } = servers;
    const { location } = user;
    const lang = apps.currentLanguage;
    const brewShipAt = serialRegistrationDate ? new Date(serialRegistrationDate) : new Date();

    let final_date = brewShipAt.toLocaleString();

    if (!isOk(servers?.locations)) return;

    // restaurants
    const defaultRest = 0;

    const rests = servers?.locations
      .map((locObj: ILocation) => {
        if (locObj.id === restLocId) {
          this.restValue = { ...locObj };
        }
        let locCategoryName;
        let displayLabel = locObj.name;
        switch (locObj.category) {
          case ECategoryType.BREWERY:
            locCategoryName = appLanguages.brewery[lang];
            break;
          case ECategoryType.DISTRIBUTOR:
            locCategoryName = appLanguages.distributor[lang];
            break;
          case ECategoryType.RESTAURANT:
            locCategoryName = appLanguages.restaurant[lang];
            if (isOk(locObj?.customerCode) && locObj?.customerCode !== locObj?.name) {
              displayLabel = `${locObj?.name}（${locObj.customerCode}）`;
            }
            break;
        }
        return { ...locObj, displayLabel, categoryName: locCategoryName };
      })
      .sort((a, b) =>
        a.category != b.category ? a.category - b.category : a.name < b.name ? -1 : 1,
      );
    const step1View = (
      <>
        {getAutocomplete(
          `${appLanguages.shippingDestination[lang]}`,
          `${appLanguages.pleaseSelect[lang]}`,
          true,
          { ...this.restValue },
          [...rests],
          (e, v) => {
            this.handleChangeRestLocIdAndLocCategory(
              (v && Number(v.id)) || defaultRest,
              (v && v.category) || ECategoryType.ADMIN,
              (v && v.subCategory) || 0,
            );
          },
          undefined,
          undefined,
          undefined,
          (loc) => loc.categoryName,
        )}
        {getTextField(
          `${appLanguages.invoiceNumber[lang]}`,
          `${appLanguages.pleaseEnter[lang]}`,
          slipNum && String(slipNum),
          (e) => this.handleChangeSlipNum(e),
        )}
        {subCategory === 5 &&
          getTextField(
            `${appLanguages.enterCustomerNumber[lang]}`,
            `${appLanguages.pleaseEnter[lang]}`,
            `${customerNumber || ''}`,
            (e) => this.handleChangeCustomerNumber(e),
          )}
        {subCategory === 6 &&
          getTextField(
            `${appLanguages.enterEventName[lang]}`,
            `${appLanguages.pleaseEnter[lang]}`,
            `${eventName || ''}`,
            (e) => this.handleChangeEventName(e),
          )}
        {subCategory === 7 &&
          getTextField(
            `${appLanguages.enterPurpose[lang]}`,
            `${appLanguages.pleaseEnter[lang]}`,
            `${purpose || ''}`,
            (e) => this.handleChangePurpose(e),
          )}
      </>
    );

    //let step3DestinationDescription = '';
    if (locCategory === ECategoryType.DISTRIBUTOR) {
      this.step3DestinationDescription = appLanguages.selectDistToShip[lang];
    } else if (locCategory === ECategoryType.RESTAURANT) {
      this.step3DestinationDescription = appLanguages.restaurant2[lang];
    }

    // QRコードをスキャンする
    const step2View = <SerialQRScan operation={1} />;

    // 最終確認
    const step3View = (
      <>
        <Paper elevation={3} className={classes.paper}>
          {getTextFieldForReadOnly(this.step3DestinationDescription, this.restValue.name)}
          <Box mt={3} />
          {getTextFieldForReadOnly(
            appLanguages.mainAddress[lang],
            this.restValue.mainAddress || '-',
          )}
          <Box mt={3} />
          {getTextFieldForReadOnly(
            appLanguages.customerCode[lang],
            this.restValue.customerCode || '-',
          )}
          <Box mt={3} />
          {getTextFieldForReadOnly(appLanguages.invoiceNumber[lang], slipNum)}
          <Box mt={3} />
          {subCategory === 5 &&
            getTextFieldForReadOnly(appLanguages.enterCustomerNumber[lang], customerNumber || '-')}
          <Box mt={3} />
          {subCategory === 6 &&
            getTextFieldForReadOnly(appLanguages.enterEventName[lang], eventName || '-')}
          <Box mt={3} />
          {subCategory === 7 &&
            getTextFieldForReadOnly(appLanguages.enterPurpose[lang], purpose || '-')}
          <Box mt={2} />
          {/* シリアル番号一覧 */}
          {getScannedSerialsView(
            [...servers?.scannedSerials],
            (serial) => this.invalidSerial(serial),
            this.state,
            (s) => this.setState(s),
            classes,
            'outgoing',
          )}
          <Box mt={3} />
          {getTextFieldForReadOnly(appLanguages.serialRegistrationDate[lang], final_date)}
          <Box mt={3} />
          {/* 拠点登録位置情報からスキャン位置情報の距離 */}
          {getScannedLocationDistanceView(
            location,
            classes,
            this.state,
            (s) => this.setState(s),
            'outgoing',
          )}
        </Paper>
      </>
    );

    switch (step) {
      case 0:
        return step1View;
      case 1:
        return step2View;
      case 2:
        return step3View;
      default:
        return 'Unknown step...';
    }
  }

  protected async initScanLocationByBaiduMapAPI() {
    const {
      servers: { user },
    } = this.props;
    const scanLocationInfo = await getBaiduScannedLocationInfo(user);
    this.setState({
      distanceText: scanLocationInfo.distanceText,
      distance: scanLocationInfo.distance,
      scanLat: scanLocationInfo.scanLat,
      scanLng: scanLocationInfo.scanLng,
    });
  }

  protected initScanLocationByGoogleMapAPI() {
    const { servers } = this.props;
    const { user } = servers;
    const _initScanLocationByHtml5GeoAPI = () => {
      // HTML5 Geolocation API によるGPSによる位置情報の取得
      window.navigator.geolocation.getCurrentPosition((p) => {
        const { latitude, longitude } = p.coords;
        setScanLocationInfoByGoogleMapAPI(latitude, longitude, (s) => {
          this.setState(s);
        });
        setDistanceFromScanLocToUserLocByGoogleMapAPI(user.location, latitude, longitude, (s) =>
          this.setState(s),
        );
      });
    };
    // Google Map API (geolocate API) での位置情報取得
    new Client({})
      .geolocate({
        params: {
          key: userEnv.googleMapApiKey,
        },
        data: null,
      })
      .then((response) => {
        const d = response.data;
        if (!d || !d['location'] || !d['locatio']?.lat || !d['location']?.lng) {
          _initScanLocationByHtml5GeoAPI();
        } else {
          const { lat, lng } = d['location'];
          setScanLocationInfoByGoogleMapAPI(lat, lng, (s) => this.setState(s));
          setDistanceFromScanLocToUserLocByGoogleMapAPI(user.location, lat, lng, (s) =>
            this.setState(s),
          );
        }
      })
      .catch(() => _initScanLocationByHtml5GeoAPI());
  }

  clearAppState() {
    this.props.setBreweryRegState({
      activeStep: 0,
      locCategory: ECategoryType.ADMIN,
      restLocId: 0,
      slipNum: '',
    });
    this.setState({ ...this.initState });
  }

  protected invalidSerial(serial) {
    const {
      servers: { user },
    } = this.props;
    return checkSerialValidity(user, serial) !== 'ok';
  }

  disableNextStep(step) {
    const {
      servers,
      apps: {
        breweryRegState: { restLocId, slipNum },
        scannedSerialCodes,
      },
    } = this.props;
    switch (step) {
      case 0:
        return !restLocId || !slipNum; // レストランと伝票番号を設定
      case 1: {
        // QRコードスキャン
        // Sreenath 27thOct - Scan Processing of Deleted/not registered Serial Number
        const results = servers?.scannedSerials.filter((serial) => !this.invalidSerial(serial));
        let scannedList = []; // array the scanned serials which are available to be shipped/recieved/sold.
        scannedSerialCodes.forEach((x) => {
          // Array of scanned serials regardless of registered or not
          const isRegistered = results.find((serial) => serial.code === x); // find only the registered ones
          if (isRegistered) {
            scannedList.push(isRegistered);
          }
        });
        return !scannedList.length; // if this array is empty meaning no valid serials thus disable the next button in step form.
      }
      case 2:
        return false;
      default:
        return true;
    }
  }

  async handleNextStep() {
    const {
      setBreweryRegState,
      apps: { breweryRegState, scannedSerialCodes, serialRegistrationDate },
      getScannedSerials,
      clearScannedSerialCode,
      registerSerials,
      setTab,
      servers,
    } = this.props;

    const newActiveStep = breweryRegState?.activeStep + 1;
    setBreweryRegState({
      ...breweryRegState,
      activeStep: newActiveStep,
    });

    if (newActiveStep === 2) {
      // Get all serials to register
      await getScannedSerials(getQueryForScannedSerials(scannedSerialCodes));
    }

    if (newActiveStep > 2) {
      // 「完了」ボタンをクリックした時の動作

      const { user } = servers;
      const { location } = user;
      const { scanLat, scanLng, warning } = this.state;
      const brewLocId = location ? location.id : null;
      const brewShipAt = serialRegistrationDate
        ? new Date(serialRegistrationDate).toISOString()
        : new Date().toISOString();

      const brewShipScanLatLng = scanLat && scanLng ? `${scanLat},${scanLng}` : null;

      let shipDistLocId = null;
      let shipRestLocId = null;

      const geocode: IGeocode = {
        latitude: scanLat ? scanLat : null,
        longitude: scanLng ? scanLng : null,
      };

      if (breweryRegState?.locCategory === ECategoryType.DISTRIBUTOR) {
        shipDistLocId = breweryRegState?.restLocId;
      } else if (breweryRegState?.locCategory === ECategoryType.RESTAURANT) {
        shipRestLocId = breweryRegState?.restLocId;
      } else {
        throw 'Error: Location Category is not defined';
      }

      const registeringSerials = [];
      for (const serial of this.props.servers?.scannedSerials) {
        if (!this.invalidSerial(serial)) {
          const bulk = {
            ...serial,
            slip: breweryRegState?.slipNum,
            brewLocId,
            brewShipAt,
            brewShipScanLatLng,
            brewShipDistLocId: shipDistLocId,
            brewShipRestLocId: shipRestLocId,
            brewShipWarning: warning,
            requireSurveyForBrewShip: warning,
            brewShipUserId: user.id,
            customerNumber: breweryRegState?.customerNumber,
            eventName: breweryRegState?.eventName,
            purpose: breweryRegState?.purpose,
          };
          registeringSerials.push(bulk);
        }
      }

      const params: ISerials = {
        action: ESerialActionType.SEND,
        slip: breweryRegState?.slipNum,
        distSlip: null,
        geocode: geocode,
        serials: registeringSerials,
      };

      // Register all Serials
      await registerSerials(params);

      // Clear AppState
      this.clearAppState();
      await getScannedSerials({ ...DefaultMasterQuery, where: { id: 0 } }); // 空っぽにする
      clearScannedSerialCode();
      this.initScanLocationByGoogleMapAPI();
      setBreweryRegState({
        activeStep: 0, // 初期状態へ
      });

      // Go to 出荷済み Tab
      setTab(2);
    }
  }

  handleBackStep() {
    const {
      apps: { breweryRegState },
      setBreweryRegState,
    } = this.props;
    setBreweryRegState({
      ...breweryRegState,
      activeStep: breweryRegState?.activeStep - 1,
    });
  }

  handleChangeRestLocIdAndLocCategory(
    restLocId: number,
    locCategory: ECategoryType,
    subCategory: number,
  ) {
    const {
      apps: { breweryRegState },
      setBreweryRegState,
    } = this.props;
    setBreweryRegState({
      ...breweryRegState,
      restLocId,
      locCategory,
      subCategory,
    });
  }

  handleChangeSlipNum(e) {
    const {
      apps: { breweryRegState },
      setBreweryRegState,
    } = this.props;
    setBreweryRegState({
      ...breweryRegState,
      slipNum: e.target.value,
    });
  }

  handleChangeCustomerNumber(e) {
    const {
      apps: { breweryRegState },
      setBreweryRegState,
    } = this.props;
    setBreweryRegState({ ...breweryRegState, customerNumber: e.target.value });
  }

  handleChangeEventName(e) {
    const {
      apps: { breweryRegState },
      setBreweryRegState,
    } = this.props;
    setBreweryRegState({ ...breweryRegState, eventName: e.target.value });
  }

  handleChangePurpose(e) {
    const {
      apps: { breweryRegState },
      setBreweryRegState,
    } = this.props;
    setBreweryRegState({ ...breweryRegState, purpose: e.target.value });
  }

  render() {
    const {
      apps,
      classes,
      apps: {
        breweryRegState: { activeStep },
      },
    } = this.props;
    //let { restValue, step3DestinationDescription } = this.state;
    const lang = apps.currentLanguage;
    const stepTitles = [
      appLanguages.setShipDestAndInvoice[lang],
      appLanguages.registerOutgoingProducts[lang],
      appLanguages.confirmAndCompleteOutgoingProductRegistration[lang],
    ];
    const label1 = appLanguages.setShipDestAndInvoice[lang];
    const label2 = appLanguages.registerOutgoingProducts[lang];
    const label3 = appLanguages.confirmAndCompleteOutgoingProductRegistration[lang];
    return (
      <>
        <Stepper activeStep={activeStep} orientation='vertical'>
          <Step key={label1}>
            <StepLabel>{label1}</StepLabel>
            <StepContent>
              {this.getStepContent(0)}
              <Box mt={3} />
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    variant='contained'
                    color='secondary'
                    onClick={() => this.handleBackStep()}
                    className={classes.button}
                  >
                    {appLanguages.back[lang]}
                  </Button>
                  <Button
                    disabled={this.disableNextStep(activeStep)}
                    variant='contained'
                    color='primary'
                    onClick={() => this.handleNextStep()}
                    className={classes.button}
                  >
                    {activeStep === stepTitles.length - 1
                      ? appLanguages.finish[lang]
                      : appLanguages.next[lang]}
                  </Button>
                </div>
              </div>
            </StepContent>
            {activeStep === 1 ? (
              <Paper elevation={3} className={classes.paper}>
                {getTextFieldForReadOnly(this.step3DestinationDescription, this.restValue.name)}
                <Box mt={3} />
                {getTextFieldForReadOnly(appLanguages.mainAddress[lang], this.restValue.mapAddress)}
              </Paper>
            ) : null}
          </Step>

          <Step key={label2}>
            <StepLabel>{label2}</StepLabel>
            <StepContent>
              {this.getStepContent(1)}
              <Box mt={3} />
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    variant='contained'
                    color='secondary'
                    onClick={() => this.handleBackStep()}
                    className={classes.button}
                  >
                    {appLanguages.back[lang]}
                  </Button>
                  <Button
                    disabled={this.disableNextStep(activeStep)}
                    variant='contained'
                    color='primary'
                    onClick={() => this.handleNextStep()}
                    className={classes.button}
                  >
                    {activeStep === stepTitles.length - 1
                      ? appLanguages.finish[lang]
                      : appLanguages.next[lang]}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
          <Step key={label3}>
            <StepLabel>{label3}</StepLabel>
            <StepContent>
              {this.getStepContent(2)}
              <Box mt={3} />
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    variant='contained'
                    color='secondary'
                    onClick={() => this.handleBackStep()}
                    className={classes.button}
                  >
                    {appLanguages.back[lang]}
                  </Button>
                  <Button
                    disabled={this.disableNextStep(activeStep)}
                    variant='contained'
                    color='primary'
                    onClick={() => this.handleNextStep()}
                    className={classes.button}
                  >
                    {activeStep === stepTitles.length - 1
                      ? appLanguages.finish[lang]
                      : appLanguages.next[lang]}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
}

export interface IDispatchProps {
  getLocations: (query: IQuery) => void;
  setLocationsQuery: (query: IQuery) => void;
  clearScannedSerialCode: () => void;
  getScannedSerials: (query: IQuery) => void;
  registerSerial: (serialObj: ISerial) => void;
  registerSerials: (serialObj: ISerials, successMessage?: string) => void;
  setBreweryRegState: (state: IRegistrationState) => void;
  setTab: (tabIndex: number) => void;
}

interface State {
  scanLat: number;
  scanLng: number;
  scanCountry: string;
  scanArea: string;
  scanCity: string;
  scanSubCity: string;
  scanPostalCode: string;
  distanceText: string;
  distance: number;
  warning: boolean;
  openScannedSerials: boolean;
  openSerialBrandMap: any;
  openLocations: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  getLocations,
  setLocationsQuery,
  clearScannedSerialCode,
  getScannedSerials,
  registerSerial,
  registerSerials,
  setBreweryRegState,
  setTab,
};

const myStyles = (theme: Theme): StyleRules => ({
  root: { width: '100%' },
  button: { marginTop: theme.spacing(1), marginRight: theme.spacing(1) },
  actionsContainer: { marginBottom: theme.spacing(2) },
  resetContainer: { padding: theme.spacing(3) },
  paper: { padding: theme.spacing(2) },
});

export const SakeRegForBrewery = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(SakeRegForBreweryClass);
