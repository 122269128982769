import i18n from '@utils/app-languages';
import { TextField } from '@material-ui/core';
import { TSupportedLanguages } from '@models/supported-languages';
import {
  convertBaiduToGoogleCoordinates,
  convertGoogleToBaiduCoordinates,
} from '@utils/baidu-utils/conversion';
import { LANGUAGE_DEFAULT, SHANGHAI_COORDINATES } from '@utils/common';
import { isOk } from '@utils/is-ok';
import { Coords } from 'google-map-react';
import React, { Fragment } from 'react';
import styles from './style.scss';
const BMap = require('BMap');

export class MapViewBaidu extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    isMapClickable: false,
    coords: SHANGHAI_COORDINATES,
    showAutoComplete: false,
    lang: LANGUAGE_DEFAULT,
    identifier: '',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      addressValue: props.addressValue,
      coords: convertGoogleToBaiduCoordinates(props.coords.lat, props.coords.lng),
    };

    this.layPoint = this.layPoint.bind(this);
    this.setPlace = this.setPlace.bind(this);
    this.mapOnConfirm = this.mapOnConfirm.bind(this);
    this.mapOnClick = this.mapOnClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleLocationUpdate = this.handleLocationUpdate.bind(this);
  }

  public componentDidMount() {
    const { coords } = this.state;
    this.layPoint(coords);
  }

  public setPlace(value) {
    const This = this;
    const { addressValue } = This.state;
    const { identifier } = This.props;
    const map = new BMap.Map(`baidu-address-${identifier}`, { enableMapClick: false });
    let local;
    let point = null;
    local = new BMap.LocalSearch(map, {
      onSearchComplete: fn,
    });
    local.search(value);

    function fn() {
      if (local.getResults() !== undefined && local.getResults().getPoi(0)) {
        point = local.getResults().getPoi(0).point;
        This.layPoint(point);
        This.setState({
          coords: point,
        });
        This.handleLocationUpdate(addressValue, point);
      }
    }
  }

  protected layPoint(address?: any) {
    const { isMapClickable, identifier } = this.props;
    const map = new BMap.Map(`baidu-address-${identifier}`, { enableMapClick: isMapClickable });
    map.clearOverlays();
    if (isOk(address)) {
      const point = new BMap.Point(address.lng, address.lat);
      map.centerAndZoom(point, 16);
      const marker = new BMap.Marker(point);
      map.addOverlay(marker);
      if (isMapClickable) {
        marker.enableDragging();
        marker.addEventListener('dragend', this.mapOnClick);
      }
    }

    this.mapOnConfirm(map);
    map.addEventListener('click', this.mapOnClick);
    map.enableScrollWheelZoom();
    map.addControl(new BMap.NavigationControl());
    map.addControl(new BMap.ScaleControl());
  }

  protected mapOnConfirm(map) {
    const This = this;
    const ac = new BMap.Autocomplete({
      input: `baidu-autocomplete-${this.props.identifier}`,
      location: map,
    });
    ac.addEventListener('onconfirm', function (e) {
      let addressValue;
      const _value = e.item.value;
      addressValue = _value.province + _value.street + _value.business;
      This.setState({
        addressValue,
      });
      This.setPlace(addressValue);
    });
  }

  protected mapOnClick(e) {
    const This = this;
    This.layPoint(e.point);
    const geoc = new BMap.Geocoder();
    geoc.getLocation(e.point, function (rs) {
      let addressValue;
      if (rs.surroundingPois.length > 0) {
        addressValue = rs.surroundingPois[0].title;
      } else {
        const _value = rs.addressComponents;
        addressValue = _value.district + _value.city + _value.street + _value.streetNumber;
      }
      This.setState({
        addressValue,
        coords: rs.point,
      });
      This.handleLocationUpdate(addressValue, rs.point);
    });
  }

  protected onChange(e) {
    this.setState({
      addressValue: e.target.value,
    });
  }

  protected handleLocationUpdate(addressValue: string, coords: Coords) {
    const { onAddressChange } = this.props;
    if (!onAddressChange || !isOk(coords?.lat) || !isOk(coords?.lng)) return;
    const convertedGoogleMapCoords: Coords = convertBaiduToGoogleCoordinates(
      coords?.lat,
      coords?.lng,
    );
    onAddressChange({
      addressValue,
      coords: convertedGoogleMapCoords,
    });
  }

  protected renderAutoComplete() {
    const { showAutoComplete, lang, identifier } = this.props;
    const { addressValue } = this.state;

    if (!showAutoComplete) return;
    return (
      <div className={styles.autoComplete}>
        <TextField
          fullWidth
          id={`baidu-autocomplete-${identifier}`}
          placeholder={i18n.mapAddressBaidu[lang]}
          value={addressValue}
          onChange={this.onChange}
          className={styles.autoComplete}
        />
      </div>
    );
  }

  public render() {
    const { identifier } = this.props;
    return (
      <Fragment>
        {this.renderAutoComplete()}
        <div id={`baidu-address-${identifier}`} className={styles.mapView} />
      </Fragment>
    );
  }
}

export type Props = StateProps & DispatchProps;

export interface StateProps {
  coords?: Coords;
  addressValue?: string;
  isMapClickable?: boolean;
  showAutoComplete?: boolean;
  lang?: TSupportedLanguages;
  identifier?: string;
}

export interface DispatchProps {
  onAddressChange?: (params: BaiduMapLocation) => void;
}

export interface BaiduMapLocation {
  addressValue: string;
  coords: Coords;
}

interface State {
  addressValue?: string;
  coords?: Coords;
}
