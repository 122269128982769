import { MapViewBaidu } from '@components/visual-components/map-view-baidu';
import { Client, Status } from '@googlemaps/google-maps-services-js';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Clear as ClearIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  FilterList as FilterListIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { useConfirm } from 'material-ui-confirm';
import React from 'react';
import { DateRangePicker } from 'rsuite';
import userEnv from 'userEnv';

import GoogleMapView from '../components/main/sake-registrations/GoogleMapView';
import { store } from '../redux/reducers';
import appLanguages from './app-languages';
// import { functions } from './firebase';
import { formatString } from './format-string';
import { isOk } from './is-ok';

const { afterToday } = DateRangePicker;

export const test = '';

export const BAIDU_ONLY_COUNTRIES = ['CN'];

export const SHANGHAI_COORDINATES = { lat: 121.48, lng: 31.236 };

export const LANGUAGE_JAPANESE = 'ja';

export const LANGUAGE_ENGLISH = 'en';

export const LANGUAGE_CHINESE = 'zhcn';

export const LANGUAGE_CHINESE_TRADITIONAL = 'zhtw';

export const LANGUAGE_KOREAN = 'ko';

export const LANGUAGE_THAI = 'th';

export const LANGUAGE_VIETAMESE = 'vi';

// Sreenath28Sept2021- Change Default Language
export const LANGUAGE_DEFAULT = 'en';

export const SERIAL_LIMIT_COUNT = 10000; //1000

export const SERIAL_PAGE_LIMIT_COUNT = 50;

export const SERIAL_DIGIT = 5; //7 #Duval04Oct2021 - change back to 5 digit serials

export const SERIAL_ACTION = {
  receive: 'receive',
  ship: 'ship',
  sell: 'sell',
  cancel: 'cancel',
  editStatus: 'editStatus',
};

export const nowrap = (label) => (
  <Typography variant='body2' noWrap>
    {label}
  </Typography>
);

export const nowrapHeader = (label) => (
  <Typography variant='body2' color='textSecondary' noWrap>
    {label}
  </Typography>
);

export const __nowrapHeader = (label) => label;

export const isString = (v) => v && (typeof v === 'string' || v instanceof String);

export const isNum = (v) => !isNaN(parseInt(v));

export const extractSerialFromUrl = (url) => {
  if (!isString(url) || !(url.match(/http:\/\//) || url.match(/https:\/\//))) return undefined;
  const { qrDomain } = userEnv;
  if (String(url).indexOf(qrDomain) === -1) return undefined;
  const tmpList = url.split('/');
  const serial = tmpList[tmpList.length - 1].trim();
  if (isNaN(parseInt(serial))) return undefined;
  if (serial.length < 5) return undefined;
  return serial;
};

export const getSerialCodeErrorMessage = (serialCode) => {
  const lang = store.getState().apps.currentLanguage;
  if (!serialCode) return '';
  if (String(serialCode).match(/[^0-9,]+/)) {
    return appLanguages.onlyNumbers[lang];
  }
  if (String(serialCode).length > SERIAL_DIGIT) {
    return formatString(appLanguages.serialLength[lang], { field: SERIAL_DIGIT.toString() });
  }
  return '';
};

export const withConfirm = (Component) => (props) => (
  <Component confirm={useConfirm()} {...props} />
);

export const setScanLocationInfoByGoogleMapAPI = (lat, lng, setState) => {
  if (!lat || !lng) return;
  const language = store.getState().apps.currentLanguage;
  setState({ scanLat: lat });
  setState({ scanLng: lng });
  new Client({})
    .geocode({ params: { language, key: userEnv.googleMapApiKey, address: `${lat},${lng}` } })
    .then((response) => {
      const { data } = response;
      const { status } = data;
      if (Status.OK !== status || !data) return;
      const { results } = data;
      if (!Array.isArray(results) || !results.length) return;
      const result = results[0];
      const addrComps = result.address_components;
      for (const comp of addrComps) {
        const name = comp.long_name;
        const { types } = comp;
        if (Array.isArray(types) && isOk(types)) {
          // country, postal_code, administrative_area, sublocality, locality
          if (types.filter((v) => v.match(/postal_code/)).length) {
            setState({ scanPostalCode: name });
          } else if (types.filter((v) => v.match(/sublocality/)).length) {
            setState({ scanSubCity: name });
          } else if (types.filter((v) => v.match(/locality/)).length) {
            setState({ scanCity: name });
          } else if (types.filter((v) => v.match(/administrative_area/)).length) {
            setState({ scanArea: name });
          } else if (types.filter((v) => v.match(/country/)).length) {
            setState({ scanCountry: name });
          }
        }
      }
    });
};

export const setDistanceFromScanLocToUserLocByGoogleMapAPI = (
  userLocation,
  scanLat,
  scanLng,
  setState,
) => {
  const { latitude, longitude, range } = userLocation;
  if (!isNum(latitude) || !isNum(longitude) || !isNum(scanLat) || !isNum(scanLng)) return;
  // const params = {
  //   key: userEnv.googleMapApiKey,
  //   origin: `${latitude},${longitude}`,
  //   destination: `${scanLat},${scanLng}`,
  // };

  function getDistanceFromLatLon(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371000; // Radius of the earth in m
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in m
    return d;
  }

  function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }
  const dist = getDistanceFromLatLon(latitude, longitude, scanLat, scanLng);
  setState({ distanceText: dist, distance: dist });
  setState({ warning: dist ? range < dist : false });
  //   const request = functions.httpsCallable('getDirections');
  //   request(params)
  //     .then((result) => {
  //       const { data } = result;
  //       const { text, value } = data;
  //       setState({ distanceText: text, distance: value });
  //       setState({ warning: value ? range < value : false });
  //     })
  //     .catch((error) => window.console.error(error))
  //     .finally(() => null);
};

export const calDistance = (userLocation, scanLat, scanLng) => {
  const { latitude, longitude } = userLocation;
  if (!isNum(latitude) || !isNum(longitude) || !isNum(scanLat) || !isNum(scanLng)) return;
  function getDistanceFromLatLon(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371000; // Radius of the earth in m
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in m
    return d;
  }

  function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }
  const dist = getDistanceFromLatLon(latitude, longitude, scanLat, scanLng);
  return dist;
};

export const getFormattedDateString = (date) => {
  const y = date.getFullYear();
  const month = ('00' + (date.getMonth() + 1)).slice(-2);
  const d = ('00' + date.getDate()).slice(-2);
  const h = ('00' + date.getHours()).slice(-2);
  const m = ('00' + date.getMinutes()).slice(-2);
  return `${y}/${month}/${d} ${h}:${m}`;
};

export const getDoneTitle = (title) => (
  <Grid container>
    <Grid item>
      <DoneIcon fontSize='inherit' />
    </Grid>
    <Grid item>
      <Box ml={1} />
    </Grid>
    <Grid item>
      <Typography variant='subtitle2'>{title}</Typography>
    </Grid>
  </Grid>
);

export const getAutocomplete = (
  label,
  placeholder,
  autoFocus,
  value,
  options,
  onChange,
  error = false,
  helperText = '',
  margin: 'none' | 'dense' | 'normal' = 'dense',
  groupBy?: any,
) => (
  <Autocomplete
    disableClearable
    value={value}
    options={options}
    getOptionLabel={(o) => o?.displayLabel || o?.name || ''}
    onChange={onChange}
    groupBy={groupBy}
    renderInput={(p) => {
      return (
        <TextField
          {...p}
          autoFocus={autoFocus}
          label={label}
          placeholder={placeholder}
          margin={margin}
          fullWidth
          size='small'
          error={error}
          helperText={helperText}
        />
      );
    }}
  />
);

export const getAutocompleteAdminEditDialog = (
  label,
  placeholder,
  autoFocus,
  value,
  options,
  onChange,
  disabled,
  error = false,
  helperText = '',
  margin: 'none' | 'dense' | 'normal' = 'dense',
  groupBy?: any,
) => (
  <Autocomplete
    disableClearable
    value={value}
    options={options}
    getOptionLabel={(o) => o?.displayLabel || o?.name || ''}
    onChange={onChange}
    groupBy={groupBy}
    disabled={disabled}
    renderInput={(p) => (
      <TextField
        {...p}
        autoFocus={autoFocus}
        label={label}
        placeholder={placeholder}
        margin={margin}
        fullWidth
        size='small'
        error={error}
        helperText={helperText}
      />
    )}
  />
);

export const getTextFieldForSmall = (
  label,
  placeholder,
  value,
  onChange,
  error = false,
  helperText = '',
) => (
  <TextField
    fullWidth
    label={label}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    error={error}
    helperText={helperText}
    margin='none'
    size='small'
  />
);

export const getTextField = (label, placeholder, value, onChange) => (
  <TextField
    fullWidth
    label={label}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    margin='dense'
  />
);

export const getTextFieldProfile = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    margin='normal'
    disabled={disabled}
  />
);

export const getTextFieldForPassword = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    margin='normal'
    type='password'
    disabled={disabled}
  />
);

export const getTextFieldForReadOnly = (label, value) => (
  <TextField
    size='small'
    fullWidth
    label={label}
    value={value}
    variant='outlined'
    InputProps={{ readOnly: true }}
  />
);

export const getScannedSerialsView = (
  scannedSerials = [],
  invalidSerial,
  state,
  setState,
  classes,
  type: 'incoming' | 'outgoing' = 'incoming',
) => {
  const { openScannedSerials, openSerialBrandMap } = state;
  const lang = store.getState().apps.currentLanguage;
  const brandMap: { brand?: any; count?: any } = {};
  let totalCount = 0;
  if (scannedSerials.length) {
    for (const serial of scannedSerials) {
      if (invalidSerial(serial)) continue;
      const { brand } = serial;
      if (brandMap[brand.id]) brandMap[brand.id].count += 1;
      else brandMap[brand.id] = { brand, count: 1 };
      totalCount += 1;
    }
  }
  return (
    <>
      <Grid container justify='flex-start' alignContent='flex-start' alignItems='center'>
        <Grid item xs>
          <Typography variant='subtitle2' color='textSecondary'>
            {`${
              appLanguages[type === 'outgoing' ? 'outgoingScannedCount' : 'scannedSerials'][lang]
            }: `}
            {formatString(appLanguages.totalCount[lang], { field: totalCount.toString() })};
          </Typography>
        </Grid>
        <Grid item xs={2} sm={6}>
          <IconButton
            size='small'
            onClick={() =>
              setState({ openScannedSerials: !openScannedSerials, openSerialBrandMap: {} })
            }
          >
            {openScannedSerials ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Box mt={0.1} />
      {openScannedSerials ? (
        <Collapse in={openScannedSerials}>
          <Paper elevation={3}>
            <List>
              {Object.entries(brandMap).map(([brandId, { brand, count }], index) => {
                let serialIndex = -1;
                return (
                  <React.Fragment key={brandId}>
                    {index ? <Divider variant='middle' component='li' /> : ''}
                    <ListItem>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Grid item xs>
                              <Typography variant='subtitle2' color='textSecondary'>
                                {`${brand.name}: 計${count}本`}
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sm={4}>
                              <IconButton
                                size='small'
                                onClick={() => {
                                  setState({
                                    openSerialBrandMap: {
                                      ...openSerialBrandMap,
                                      [brand.id]: !openSerialBrandMap[brand.id],
                                    },
                                  });
                                }}
                              >
                                {openSerialBrandMap[brand.id] ? (
                                  <KeyboardArrowDownIcon />
                                ) : (
                                  <KeyboardArrowRightIcon />
                                )}
                              </IconButton>
                            </Grid>
                            {openSerialBrandMap[brand.id] ? (
                              <Grid item xs={12}>
                                {scannedSerials.map((serial) => {
                                  if (invalidSerial(serial) || serial.brand.id !== brand.id)
                                    return '';
                                  serialIndex += 1;
                                  return (
                                    <React.Fragment key={serial.id}>
                                      {serialIndex ? <Box mt={1} /> : ''}
                                      <Paper
                                        elevation={1}
                                        key={serial.id}
                                        className={classes.paper}
                                      >
                                        <Typography variant='caption' color='textPrimary'>
                                          {`${appLanguages.serialCode[lang]}: ${serial.code}`}
                                        </Typography>
                                      </Paper>
                                    </React.Fragment>
                                  );
                                })}
                              </Grid>
                            ) : (
                              ''
                            )}
                          </Grid>
                        }
                      />
                    </ListItem>
                  </React.Fragment>
                );
              })}
            </List>
          </Paper>
        </Collapse>
      ) : (
        ''
      )}
    </>
  );
};

export const getLocationView = (title, address, lat, lng) => (
  <>
    <Typography variant='subtitle2' color='textPrimary'>
      {title}
    </Typography>
    <Paper elevation={0}>
      <Typography variant='caption' color='textSecondary'>
        {address}
      </Typography>
      <Box mt={1} />
      <GoogleMapView apiKey={userEnv.googleMapApiKey} lat={lat} lng={lng} zoom={15} />
    </Paper>
  </>
);
export const getBaiduLocationView = (title, address, lat, lng, lang) => (
  <>
    <Typography variant='subtitle2' color='textPrimary'>
      {title}
    </Typography>
    <Paper elevation={0}>
      <Typography variant='caption' color='textSecondary'>
        {address}
      </Typography>
      <Box mt={1} />
      <MapViewBaidu
        coords={{ lat: lat, lng: lng }}
        //addressValue={`${address}`}
        lang={lang}
        //identifier='scan1'
      />
    </Paper>
  </>
);

export const getScannedLocationDistanceView = (
  location,
  classes,
  state,
  setState,
  type: 'incoming' | 'outgoing' = 'incoming',
) => {
  const lang = store.getState().apps.currentLanguage;

  const mapMode: 'baidu' | 'google-maps' = BAIDU_ONLY_COUNTRIES.includes(
    store?.getState()?.servers?.user?.location?.country,
  )
    ? 'baidu'
    : 'google-maps';

  const {
    scanLat,
    scanLng,
    scanCountry,
    scanArea,
    scanCity,
    scanSubCity,
    scanPostalCode,
    distance,
    distanceText,
    openLocations,
  } = state;
  return (
    <>
      <Grid container>
        <Grid item xs>
          <Typography variant='subtitle2' color='textSecondary'>
            {`${formatString(appLanguages.distanceBetweenRegisteredLocation[lang], {
              location:
                type === 'outgoing'
                  ? appLanguages.outgoingLocation[lang]
                  : appLanguages.scannedLocation[lang],
            })} ${distanceText}`}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={6}>
          <IconButton size='small' onClick={() => setState({ openLocations: !openLocations })}>
            {openLocations ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Box mt={0.1} />
      {openLocations ? (
        <Collapse in={openLocations}>
          <Paper elevation={3} className={classes.paper}>
            {/* スキャン位置の表示 */}
            {mapMode === 'google-maps' &&
              getLocationView(
                `${appLanguages.positionInfoWhenScanning[lang]}`,
                `${scanPostalCode} ${scanCountry} ${scanArea} ${scanCity} ${scanSubCity}`,
                scanLat,
                scanLng,
              )}

            {mapMode === 'baidu' &&
              getBaiduLocationView(
                `${appLanguages.positionInfoWhenScanning[lang]}`,
                `${scanPostalCode} ${scanCountry} ${scanArea} ${scanCity} ${scanSubCity}`,
                scanLat,
                scanLng,
                lang,
              )}

            <Box mt={2} />
            <Divider variant='fullWidth' />
            <Box mt={2} />

            {/* 登録位置の表示 */}
            {mapMode === 'google-maps' &&
              getLocationView(
                `${appLanguages.registeredLocationInformation[lang]}`,
                `${location.mainAddress || location.mapAddress}`,
                location.latitude,
                location.longitude,
              )}

            {mapMode === 'baidu' && (
              <div>
                <Typography variant='subtitle2' color='textPrimary'>
                  {appLanguages.registeredLocationInformation[lang]}
                </Typography>
                <MapViewBaidu
                  coords={{ lat: location.latitude, lng: location.longitude }}
                  addressValue={`${location.mainAddress || location.mapAddress}`}
                  lang={lang}
                  identifier='scan2'
                />
              </div>
            )}
          </Paper>
        </Collapse>
      ) : (
        ''
      )}
      {location.range < distance ? (
        <>
          <Box mt={1} />
          <Alert icon={false} variant='outlined' severity='error'>
            {'【警告】 '}
            <br />
            <small>{appLanguages.checkBrowserGPS[lang]}</small>
          </Alert>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export const getFilterButton = (open, onClick, lang = LANGUAGE_DEFAULT) => (
  <>
    {open ? (
      <Tooltip title={appLanguages.filterClose[lang]}>
        <IconButton size='small' onClick={onClick}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Button variant='text' color='inherit' startIcon={<FilterListIcon />} onClick={onClick}>
        <Typography variant='inherit' noWrap>
          {appLanguages.filter[lang]}
        </Typography>
      </Button>
    )}
  </>
);

export const getDateRangePicker = (label, value, onChange, lang = LANGUAGE_DEFAULT) => (
  <Box mt={1.5} mb={1.5}>
    <Grid container alignItems='center'>
      <Grid item>
        <Typography variant='caption' color='textSecondary' noWrap>
          {label}
        </Typography>
      </Grid>
    </Grid>
    <Grid container alignItems='center'>
      <Grid item>
        {/* 参考: https://rsuitejs.com/en/components/date-range-picker/ */}
        <DateRangePicker
          placeholder={appLanguages.inputPeriod[lang]}
          size='sm'
          format='YYYY/MM/DD'
          value={value}
          onChange={onChange}
          style={{ width: 'auto' }}
          disabledDate={afterToday()}
          locale={{
            sunday: appLanguages.daySunday[lang],
            monday: appLanguages.dayMonday[lang],
            tuesday: appLanguages.dayTuesday[lang],
            wednesday: appLanguages.dayWednesday[lang],
            thursday: appLanguages.dayThursday[lang],
            friday: appLanguages.dayFriday[lang],
            saturday: appLanguages.daySaturday[lang],
            ok: appLanguages.ok[lang],
            today: appLanguages.today[lang],
            yesterday: appLanguages.yesterday[lang],
            last7Days: appLanguages.lastSevenDays[lang],
          }}
        />
      </Grid>
    </Grid>
  </Box>
);

export const getDateRangePickerSummaryFunction = (
  label,
  value,
  onChange,
  lang = LANGUAGE_DEFAULT,
) => (
  <Box mt={1.5} mb={1.5}>
    <Grid container alignItems='center'>
      <Grid item>
        <Typography variant='caption' color='textSecondary' noWrap>
          {label}
        </Typography>
      </Grid>
    </Grid>
    <Grid container alignItems='center'>
      <Grid item>
        {/* 参考: https://rsuitejs.com/en/components/date-range-picker/ */}
        <DateRangePicker
          placeholder={appLanguages.inputPeriod[lang]}
          size='sm'
          format='YYYY/MM/DD'
          value={value}
          onChange={onChange}
          style={{ width: 'auto' }}
          locale={{
            sunday: appLanguages.daySunday[lang],
            monday: appLanguages.dayMonday[lang],
            tuesday: appLanguages.dayTuesday[lang],
            wednesday: appLanguages.dayWednesday[lang],
            thursday: appLanguages.dayThursday[lang],
            friday: appLanguages.dayFriday[lang],
            saturday: appLanguages.daySaturday[lang],
            ok: appLanguages.ok[lang],
          }}
        />
      </Grid>
    </Grid>
  </Box>
);

export const getSmallClearButton = (label, disabled, onClick) => (
  <Button
    size='small'
    variant='outlined'
    color='default'
    startIcon={<ClearIcon fontSize='small' />}
    disabled={disabled}
    onClick={onClick}
  >
    <Typography variant='caption' noWrap>
      {label}
    </Typography>
  </Button>
);

export const getSmallTextField = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    size='small'
    variant='standard'
    label={label}
    value={value}
    onChange={onChange}
    margin='dense'
    disabled={disabled}
  />
);
