import { Client } from '@googlemaps/google-maps-services-js';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  StyleRules,
  Theme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IGeocode } from '@models/geocode';
import { EQROperations } from '@models/qr-operations';
import { IQuery } from '@models/query';
import { IRegistrationState } from '@models/registration-state';
import { ISerial } from '@models/serial';
import { IStateApps } from '@models/state-app';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import { clearScannedSerialCode, setRestaurantRegState, setTab } from '@redux/actions/appsActions';
import {
  getScannedSerials,
  registerSerial,
  registerSerials,
  storeTmpGpsCords,
  registerScanGpsCords,
} from '@redux/actions/serversActions';
import appLanguages from '@utils/app-languages';
import { getBaiduScannedLocationInfo } from '@utils/baidu-utils/get-baidu-scanned-location-info';
import { checkSerialValidity } from '@utils/check-serial-validity';
import {
  BAIDU_ONLY_COUNTRIES,
  getScannedLocationDistanceView,
  getScannedSerialsView,
  getTextFieldForReadOnly,
  SERIAL_ACTION,
  setDistanceFromScanLocToUserLocByGoogleMapAPI,
  setScanLocationInfoByGoogleMapAPI,
  calDistance,
} from '@utils/common';
import { DefaultMasterQuery } from '@utils/default-query';
import { getQueryForScannedSerials } from '@utils/get-scanned-serials-for-registration';
import { isOk } from '@utils/is-ok';
import React from 'react';
import { connect } from 'react-redux';
import userEnv from 'userEnv';

import { compose } from 'redux';

import { SerialQRScan } from './SerialQRScan';

class SakeRegForRestaurantClass extends React.PureComponent<Props, State> {
  protected initState: State;

  constructor(props) {
    super(props);
    this.initState = {
      scanLat: 0,
      scanLng: 0,
      scanCountry: '',
      scanArea: '',
      scanCity: '',
      scanSubCity: '',
      scanPostalCode: '',
      distanceText: '',
      distance: 0,
      warning: false,
      openScannedSerials: true,
      openSerialBrandMap: {},
      openLocations: true,
      isFetchingGps: true,
      gpsCords: [],
      closestGpsCords: {},
    };
    this.state = { ...this.initState };
  }

  public async componentDidMount() {
    if (BAIDU_ONLY_COUNTRIES.includes(this.props.servers?.user?.location?.country)) {
      await this.initScanLocationByBaiduMapAPI();
    } else {
      this.initScanLocationByGoogleMapAPI();
    }

    const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));
    (async () => {
      for (let i = 0; i < 12; i++) {
        await sleep(5000);
        await this.getCurrentPosition();
      }
    })();
  }

  getStepContent(step) {
    const {
      apps,
      apps: {
        restaurantRegState: { operation },
      },
      servers,
      classes,
    } = this.props;
    const { user } = servers;
    const { location } = user;

    const lang = apps.currentLanguage;

    const step1View = (
      <>
        <Box mt={2} />
        <FormControl fullWidth>
          <InputLabel id='operation-select-label'>{appLanguages.operation[lang]}</InputLabel>
          <Select
            labelId='operation-select-label'
            value={operation || ''}
            onChange={(e) => this.handleStepClick(e.target.value)}
          >
            {/* { 0: 'None', 1: 'Shipping', 2: 'Receiving', 3: 'Selling' } */}
            <MenuItem value={2}>{appLanguages.receiving[lang]}</MenuItem>
            <MenuItem value={3}>{appLanguages.selling[lang]}</MenuItem>
          </Select>
        </FormControl>
      </>
    );

    // QRコードをスキャンする
    const step2View = <SerialQRScan operation={operation} />;

    const step3View = (
      <>
        <Paper elevation={3} className={classes.paper}>
          {getTextFieldForReadOnly(
            appLanguages.operation[lang],
            userEnv.operationMap[lang][operation],
          )}
          {/* シリアル番号一覧 */}
          <Box mt={2} />
          {getScannedSerialsView(
            [...servers?.scannedSerials],
            (serial) => this.invalidSerial(serial),
            this.state,
            (s) => this.setState(s),
            classes,
            operation === EQROperations.SELLING ? 'outgoing' : 'incoming',
          )}
          <Box mt={3} />
          {/* 登録位置情報からスキャン位置情報の距離 */}
          {getScannedLocationDistanceView(
            location,
            classes,
            this.state,
            (s) => this.setState(s),
            operation === EQROperations.SELLING ? 'outgoing' : 'incoming',
          )}
        </Paper>
      </>
    );

    switch (step) {
      case 0:
        return step1View;
      case 1:
        return step2View;
      case 2:
        return step3View;
      default:
        return 'Unknown step';
    }
  }

  handleStepClick(value) {
    const {
      setRestaurantRegState,
      apps: { restaurantRegState },
    } = this.props;
    setRestaurantRegState({
      ...restaurantRegState,
      operation: Number(value),
    });
  }

  protected async initScanLocationByBaiduMapAPI() {
    const {
      servers: { user },
    } = this.props;
    const scanLocationInfo = await getBaiduScannedLocationInfo(user);
    this.setState({
      distanceText: scanLocationInfo.distanceText,
      distance: scanLocationInfo.distance,
      scanLat: scanLocationInfo.scanLat,
      scanLng: scanLocationInfo.scanLng,
    });
  }

  protected async getCurrentPosition() {
    const { servers } = this.props;
    const { gpsCords, closestGpsCords } = this.state;
    console.log(gpsCords);
    console.log(closestGpsCords);
    const { user } = servers;
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    if (user.location.country !== 'CN') {
      window.navigator.geolocation.getCurrentPosition(
        (p) => {
          const { latitude, longitude } = p.coords;
          setScanLocationInfoByGoogleMapAPI(latitude, longitude, (s) => this.setState(s));
          setDistanceFromScanLocToUserLocByGoogleMapAPI(user.location, latitude, longitude, (s) =>
            this.setState(s),
          );
          const distance = calDistance(user.location, latitude, longitude);
          const item = {
            actionId: new Date().toISOString(),
            userId: user.id,
            locationId: user.location.id,
            locLatitude: user.location.latitude,
            locLongitude: user.location.longitude,
            fetchedLatitude: latitude,
            fetchedLongitude: longitude,
            distance,
          };
          this.setState({ gpsCords: [...gpsCords, item] });
          if (closestGpsCords.fetchedLatitude) {
            if (closestGpsCords.distance > item.distance) this.setState({ closestGpsCords: item });
          } else if (!closestGpsCords.fetchedLatitude) {
            this.setState({ closestGpsCords: item });
          }
        },
        error,
        options,
      );
    } else {
      const scanLocationInfo = await getBaiduScannedLocationInfo(user);
      const distance = calDistance(
        user.location,
        scanLocationInfo.scanLat,
        scanLocationInfo.scanLng,
      );
      const item = {
        actionId: new Date().toISOString(),
        userId: user.id,
        locationId: user.location.id,
        locLatitude: user.location.latitude,
        locLongitude: user.location.longitude,
        fetchedLatitude: scanLocationInfo.scanLat,
        fetchedLongitude: scanLocationInfo.scanLng,
        distance,
      };
      this.setState({ gpsCords: [...gpsCords, item] });
      if (closestGpsCords.fetchedLatitude) {
        if (closestGpsCords.distance > item.distance) this.setState({ closestGpsCords: item });
      } else if (!closestGpsCords.fetchedLatitude) {
        this.setState({ closestGpsCords: item });
      }
    }
  }

  protected initScanLocationByGoogleMapAPI() {
    const { servers } = this.props;
    const { user } = servers;
    const _initScanLocationByHtml5GeoAPI = () => {
      // HTML5 Geolocation API によるGPSによる位置情報の取得
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }
      window.navigator.geolocation.getCurrentPosition(
        (p) => {
          const { latitude, longitude } = p.coords;
          setScanLocationInfoByGoogleMapAPI(latitude, longitude, (s) => this.setState(s));
          setDistanceFromScanLocToUserLocByGoogleMapAPI(user.location, latitude, longitude, (s) =>
            this.setState(s),
          );
        },
        error,
        options,
      );
    };
    // Google Map API (geolocate API) での位置情報取得
    new Client({})
      .geolocate({ params: { key: userEnv.googleMapApiKey }, data: null })
      .then((response) => {
        const d = response.data;
        if (!d || !d['location'] || !d['location']?.lat || !d['location']?.lng) {
          _initScanLocationByHtml5GeoAPI();
        } else {
          const { lat, lng } = d['location'];
          setScanLocationInfoByGoogleMapAPI(lat, lng, (s) => this.setState(s));
          setDistanceFromScanLocToUserLocByGoogleMapAPI(user.location, lat, lng, (s) =>
            this.setState(s),
          );
        }
      })
      .catch(() => _initScanLocationByHtml5GeoAPI());
  }

  clearAppState() {
    const { setRestaurantRegState } = this.props;
    setRestaurantRegState({
      activeStep: 0,
      operation: EQROperations.UNSPECIFIED,
    });
    this.setState({ ...this.initState });
  }

  protected invalidSerial(serial) {
    const {
      apps: {
        restaurantRegState: { operation },
      },
      servers: { user },
    } = this.props;

    return checkSerialValidity(user, serial, operation) !== 'ok';
  }

  disableNextStep(step) {
    const {
      servers,
      apps: {
        restaurantRegState: { operation },
        scannedSerialCodes,
      },
    } = this.props;
    switch (step) {
      case 0:
        return !operation;
      case 1: {
        // QRコードスキャン
        // Sreenath 27thOct - Scan Processing of Deleted/not registered Serial Number
        const results = servers?.scannedSerials.filter((serial) => !this.invalidSerial(serial));
        let scannedList = []; // array the scanned serials which are available to be shipped/recieved/sold.
        scannedSerialCodes.forEach((x) => {
          // Array of scanned serials regardless of registered or not
          const isRegistered = results.find((serial) => serial.code === x); // find only the registered ones
          if (isRegistered) {
            scannedList.push(isRegistered);
          }
        });
        return !scannedList.length; // if this array is empty meaning no valid serials thus disable the next button in step form.
      }
      case 2:
        return false;
      default:
        return true;
    }
  }

  async handleNextStep() {
    const {
      apps: { restaurantRegState, scannedSerialCodes, currentLanguage: lang },
      setRestaurantRegState,
      servers,
      clearScannedSerialCode,
      getScannedSerials,
      registerSerials,
      setTab,
      registerScanGpsCords,
    } = this.props;
    const newActiveStep = restaurantRegState?.activeStep + 1;
    setRestaurantRegState({
      ...restaurantRegState,
      activeStep: newActiveStep,
    });

    if (newActiveStep === 2) {
      // Get all serials to register
      await getScannedSerials(getQueryForScannedSerials(scannedSerialCodes));
    }

    if (newActiveStep > 2) {
      // 「完了」ボタンをクリックした時の動作
      const { user } = servers;
      const { location } = user;
      const { warning, closestGpsCords, gpsCords } = this.state;
      // const { scanLat, scanLng, warning, closestGpsCords, gpsCords } = this.state;

      // let latestDist = distance;
      let latestLat = closestGpsCords.fetchedLatitude;
      let latestLnt = closestGpsCords.fetchedLongitude;
      const restLocId = location ? location.id : null;
      const restRecvAt = new Date().toISOString();
      const restRecvScanLatLng =
        closestGpsCords.locationId == restLocId && latestLat && latestLnt
          ? `${latestLat},${latestLnt}`
          : null;
      const restSoldAt = new Date().toISOString();
      const restSoldScanLatLng =
        closestGpsCords.locationId == restLocId && latestLat && latestLnt
          ? `${latestLat},${latestLnt}`
          : null;
      const toRegisterRestaurantStates = {
        [EQROperations.RECEIVING]: [],
        [EQROperations.SELLING]: [],
      };
      const geocode: IGeocode = {
        latitude: latestLat ? latestLat : null,
        longitude: latestLnt ? latestLnt : null,
      };

      for (const serial of servers?.scannedSerials) {
        if (!this.invalidSerial(serial)) {
          // シリアル番号事に登録処理
          switch (restaurantRegState?.operation) {
            case EQROperations.RECEIVING: // Receiving
              toRegisterRestaurantStates[EQROperations.RECEIVING].push({
                ...serial,
                recvRestLocId: restLocId,
                restRecvAt,
                restRecvScanLatLng,
                restRecvWarning: warning,
                requireSurveyForRestRecv: warning,
                restRecvUserId: user.id,
              });
              break;
            case EQROperations.SELLING: // Selling
              toRegisterRestaurantStates[EQROperations.SELLING].push({
                ...serial,
                soldRestLocId: restLocId,
                restSoldAt,
                restSoldScanLatLng,
                restSoldWarning: warning,
                requireSurveyForRestSold: warning,
                restSoldUserId: user.id,
              });
              break;
            default:
              break;
          }
        }
      }

      if (isOk(toRegisterRestaurantStates[EQROperations.RECEIVING])) {
        await registerSerials({
          action: SERIAL_ACTION.receive,
          geocode: geocode,
          serials: toRegisterRestaurantStates[EQROperations.RECEIVING],
        });
      }

      if (isOk(toRegisterRestaurantStates[EQROperations.SELLING])) {
        await registerSerials(
          {
            action: SERIAL_ACTION.sell,
            geocode: geocode,
            serials: toRegisterRestaurantStates[EQROperations.SELLING],
          },
          appLanguages.soldMessage[lang],
        );
      }
      this.setState({ isFetchingGps: false });
      registerScanGpsCords(gpsCords);
      //Clear app
      this.clearAppState();
      await getScannedSerials({ ...DefaultMasterQuery, where: { id: 0 } }); // 空っぽにする
      clearScannedSerialCode();
      this.initScanLocationByGoogleMapAPI();
      setRestaurantRegState({
        ...restaurantRegState,
        activeStep: 0, // 初期状態へ
      });

      if (restaurantRegState?.operation === EQROperations.SELLING) {
        // Go to 販売済み
        setTab(2);
      } else {
        // Go to 在庫
        setTab(1);
      }
    }
  }

  handleBackStep() {
    const {
      apps: { restaurantRegState },
      setRestaurantRegState,
    } = this.props;
    setRestaurantRegState({
      ...restaurantRegState,
      activeStep: restaurantRegState?.activeStep - 1,
    });
  }

  handleFinishStep() {
    const {
      apps: { restaurantRegState },
      setRestaurantRegState,
    } = this.props;
    setRestaurantRegState({
      ...restaurantRegState,
      activeStep: 0,
    });
  }

  render() {
    const {
      apps,
      apps: {
        restaurantRegState: { activeStep },
      },
      classes,
    } = this.props;

    const lang = apps.currentLanguage;

    const stepTitles = [
      appLanguages.selectOperation[lang],
      appLanguages.registerProducts[lang],
      appLanguages.confirmAndCompleteProductRegistration[lang],
    ];

    return (
      <>
        <Stepper activeStep={activeStep} orientation='vertical'>
          {stepTitles.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {this.getStepContent(index)}
                <Box mt={3} />
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      variant='contained'
                      color='secondary'
                      onClick={() => this.handleBackStep()}
                      className={classes.button}
                    >
                      {appLanguages.back[lang]}
                    </Button>
                    <Button
                      disabled={this.disableNextStep(activeStep)}
                      variant='contained'
                      color='primary'
                      onClick={() => this.handleNextStep()}
                      className={classes.button}
                    >
                      {activeStep === stepTitles.length - 1
                        ? appLanguages.finish[lang]
                        : appLanguages.next[lang]}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
}

export interface IDispatchProps {
  getScannedSerials: (query: IQuery) => void;
  registerSerial: (serialObj: ISerial) => void;
  registerSerials: (serialObj: any, successMessage?: string) => void;
  clearScannedSerialCode: () => void;
  setRestaurantRegState: (state: IRegistrationState) => void;
  setTab: (tabIndex: number) => void;
  storeTmpGpsCords: (params: any) => void; //temporaly
  registerScanGpsCords: (params: any) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  getScannedSerials,
  registerSerial,
  clearScannedSerialCode,
  setRestaurantRegState,
  registerSerials,
  setTab,
  storeTmpGpsCords,
  registerScanGpsCords,
};

interface State {
  scanLat: number;
  scanLng: number;
  scanCountry: string;
  scanArea: string;
  scanCity: string;
  scanSubCity: string;
  scanPostalCode: string;
  distanceText: string;
  distance: number;
  warning: boolean;
  openScannedSerials: boolean;
  openSerialBrandMap: any;
  openLocations: boolean;
  isFetchingGps: boolean;
  gpsCords: any[];
  closestGpsCords: any;
}

const myStyles = (theme: Theme): StyleRules => ({
  root: { width: '100%' },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
});

export const SakeRegForRestaurant = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(SakeRegForRestaurantClass);